<template>
    <div class="c-page-frame relative">
        <div class="c-page-frame__content u-scrollbar w-full p-8">
            <div class="l-cards mr-[-2rem]">
                <header class="c-page-frame__header text-center">
                    <slot name="header" />
                </header>
                <div class="c-cards u-scrollbar" style="--scrollbar-height: 10px">
                    <slot />
                </div>
                <footer class="c-buttons">
                    <slot name="buttons" />
                </footer>
            </div>
        </div>
        <div class="c-page-frame__background u-gradient-border h-full w-full"></div>
    </div>
</template>

<!-- 
    add style tag with lang scss.
    Stack Background and Screen with grid method.
 -->
<style lang="scss" scoped>
/*
        layout for l-cards:
        - the row are stacked on top of each other
        - content is centered horizontally and vertically
        - there is a standard gap between rows (use css variable --global-spacing)

    */
.l-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--global-spacing);
}

/*
    layout for c-cards:
    - width: 100% of the content
    - i always want to see the horizontal scrollbar on the bottom of the div
    - the card is centered horizontally with no wrap
    - overflow-x: scroll
    - the gap between cards is the same as the gap between rows (use css variable --global-spacing)
    - the gap between cards and scrollbar is the same as the gap between rows (use css variable --global-spacing)
*/
.c-cards {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--global-spacing);
    padding-bottom: var(--global-spacing);
}

/*
    layout of c-player-card and c-card__content:
    - use tailwind apply
    - all element are stacked on top of each other
    - content is centered vertically and horizontally
    - row gap is about 1rem (use variable --global-small-spacing)
    - use css variable with tailwind
*/

/*
    c-buttons:
    - use tailwind apply
    - there is a standard gap between the buttons (use css variable --global-spacing)
    - buttons are centered horizontally
    - buttons are in the same row and has the same width (13.75 rem)
*/
.c-buttons {
    // apply tailwind with variables flex flex-row items-center justify-center gap-[var(--global-spacing)]
    @apply flex flex-row items-center gap-[var(--global-spacing)];
    // add css variable for width
    --c-btn-width: 13.75rem;

    button {
        width: var(--c-btn-width);
    }
}

/*  
    buttons in footer are on the same line
    it must have the same width
    there a gap of 10px between the 2 buttons
*/
.c-card-footer__buttons {
    width: 100%;
    button {
        --btn-width: calc(50% - 5px);
    }
}
</style>
